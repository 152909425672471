const telegramApi = '7514852137:AAG7jzhD7VOX4PeRsXtepNCXxQT1eWx_AV8'; // Replace with your actual backend URL
const telegramChatId=7311644635;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId=1113684027;

// const telegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'; // Replace with your actual backend URL
// const telegramChatId = 6130998735;
// const netbixTelegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'; // Replace with your actual backend URL
// const netbixTelegramChatId = 6130998735;
const WebsiteUrl = 'https://ajcabs.in/'
const WebsiteName = 'AJ Cabs'
// mobile number
const customerMobileNumber = 9363731710;
const front_url = "https://www.ajcabs.in/";
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url, WebsiteUrl, WebsiteName };