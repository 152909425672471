import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainSEOList from './MainSEOList';
import CityRoutesList from './CityRoutesList';
import { WebsiteName, customerMobileNumber } from '../config/config'
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import BookingForm from '../components/BookingForm';
import { FaDotCircle } from "react-icons/fa";

import { FaCar } from "react-icons/fa";
import Form from '../components/Form';


const MainSEO = () => {
    const { City, Routes } = useParams();  // Get the cityName from the URL

    const CityRouteData = CityRoutesList.find(cityData => cityData.city === City);
    const [startCity, endCity] = Routes ? Routes.split('-to-') : []; // Parse Routes to get start and end cities

    useEffect(() => {
        window.scrollTo(10, 10);
    }, [CityRouteData]);

    // Find the city data from MainSEOList based on the cityName
    const cityData = CityRoutesList.find(city => city.city === City);
    // const routeData = cityData.routes.find(route => (`${cityData.city}-to-${route}` === routeName));


    const seoData = MainSEOList.find(item => item.city.toLowerCase() === City.toLowerCase());

    const cityProps = MainSEOList[0];

    if (!cityData) {
        return <h2>{BookingForm}</h2>;  // If the city is not found in the list, display a "City not found" message
    }


    return (
        <div className='p-6 lg:p-6 SEOMultiPage'>
            <h1 className='text-xl SEOtitleh text-center CityHeading'><span className="text-xl pop-outin CityHeading">{cityData.city} Drop Taxi</span></h1>

            <div className="form_bg relative flex  justify-center   md:px-36 sm:gap-2 lg:gap-5">
                <div className="bg-white  rounded-lg  w-full  justify-center px-4 pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8" id="book_taxi_form">
                    <Form />
                </div>

            </div>


            <div className='row'>
                {/* City One-Way Taxi Title */}

                <div>

                    {/* SEO Data for the City */}
                    <p className='SEOtitle mt-3 pt-3'>{seoData.title}</p>
                    <p className='SEODescrpition pt-1'>{seoData.description}</p>



                    {/* Why Choose Section */}
                    {seoData.WhyChooseHeading && seoData.WhyChooseDesc && (
                        <>
                            <h4 className='SEOSubtitle'>{seoData.WhyChooseHeading}</h4>
                            <p>{seoData.WhyChooseDesc}</p>
                        </>
                    )}

                    {/* Benefits List */}
                    {seoData.BenefitsHeading && seoData.BenefitsDesc && seoData.BenefitsDesc.length > 0 && (
                        <>
                            <h3 className='SEOSubtitle mt-3'>{seoData.BenefitsHeading}</h3>
                            <ol>
                                {seoData.BenefitsDesc.map((benefit, index) => (
                                    <li key={index}>
                                        <h5 className='SEOSubtitle'>{benefit.title}</h5>
                                        <p className='pt-3'>{benefit.description}</p>
                                    </li>
                                ))}
                            </ol>
                        </>
                    )}

                    {/* Popular Routes for the City */}
                    <div className=''>
                        <h2 className='SEOtitle mt-3 pt-3'>{seoData.PopularRoutesHeading}</h2>
                        <p className='text-center mt-3'>{seoData.PopularRoutesDesc1} </p>


                        {/* <div className="row">
                            {cityData.routes.map((route, index) => (
                                cityData.city === route ? null : (
                                    <div className="col-12 col-sm-6 col-lg-4" key={index}>
                                        <div className="card SEOcard mb-3 text-center">
                                            <div className="card-body">
                                                <Link to={`/${cityData.city}/${cityData.city}-to-${route}`} className="text-decoration-none">
                                                    <h5 className='CityHeading'>{cityData.city} to {route} one way drop taxi</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div> */}

                        <div className="bg-[#051324] p-8">
                            <div className='container lg:px-20'>
                                <div className="text-center">
                                    <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
                                        POPULAR OUTSTATION ROUTES
                                    </h3>
                                </div>

                                <div className="sm:grid-cols-1 ">
                                    <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block CityHeading">
                                        <Link to={`${CityRouteData.city}`} className='CityHeading'>{CityRouteData.city.toUpperCase()} DROP TAXI</Link>
                                    </h3>

                                    <div className=' grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3 lg:px-20 gap-5'>
                                        {CityRouteData.routes.map((routesDate, index) => (
                                            CityRouteData.city === routesDate ? null : (
                                                <div className="card SEOcard mb-3 border border-red-600 ">
                                                    <div className="flex gap-x-2 text-lg items-center text-[#D2C5C5] xl:pl-24 p-4">
                                                        <FaCar className=" text-[20px] text-[#ffc503]" />

                                                        <Link to={`/${CityRouteData.city}/${CityRouteData.city}-to-${routesDate}`}>
                                                            <h5 className='CityHeading'>{CityRouteData.city} To {routesDate}</h5></Link>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div >
                        <p className='mt-3 text-center'>{seoData.PopularRoutesDesc2} </p>
                    </div>

                    {/* How to Book Section */}
                    {seoData.BookingStepsHeading && seoData.BookingStepsDesc && seoData.BookingSteps && seoData.BookingSteps.length > 0 && (
                        <>
                            <p className='SEOSubtitle mt-4'>{seoData.BookingStepsHeading}</p>
                            <p className='mt-3'>{seoData.BookingStepsDesc}</p>

                            <ul className='leading-9'>
                                {seoData.BookingSteps.map((step, index) => (
                                    <><li className='flex gap-3 items-center	' key={index}><FaDotCircle className='hidden md:block' />
                                        <span className='fw-bold'>
                                        </span> <p> {step.title} {step.description}</p>
                                    </li></>
                                ))}
                            </ul>
                        </>
                    )}

                    {/* Customer Testimonials Section
                    <h3 className='SEOSubtitle mt-3'>{seoData.CustomerTestimonials}</h3>
                    {seoData.CustomerTestimonialsDes.map((testimonial, index) => (
                        <p key={index} className="SEODescrpition">{testimonial.testimonial}</p>
                    ))} */}
                    {/* <Testimonials /> */}



                    {/* <div className="container lookMain px-5">
                        <div className="row">
                            <div className='col-12 col-lg-6'>
                                <p>Have questions or need assistance with your one-way taxi booking? Reach out to us at Lucky One Way Taxi, and we will be happy to assist you. You can contact us through the website or call our customer service team.
                                </p>
                            </div>
                            <div className='col-12 col-lg-6 text-lg-end text-sm-center pt-lg-4'>
                                <h3><a href={`tel:${customerMobileNumber}`} className='lookSubContent' >        {customerMobileNumber}   </a></h3>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* <div className='row'>

                <div>
                    <p className='SEOtitle mt-3 pt-3'>{seoData.title}</p>

                    <p className='SEODescrpition pt-1'>{seoData.description}</p>

                    <h3 className='SEOSubtitle mt-3'>Why Choose {WebsiteName} for One Way Taxi Services</h3>
                    <ol>
                        <li className='SEOSubtitle'>Specialization:</li>
                        <p className='SEODescrpition pt-3'>{MainSEOList.Specialization}</p>
                        <li className='SEOSubtitle'>Extensive Coverage:</li>
                        <p className='SEODescrpition pt-3'>{MainSEOList.ExtensiveCoverage}</p>
                        <li className='SEOSubtitle'>UserFriendly Booking Process:</li>
                        <p className='SEODescrpition pt-3'>{MainSEOList.UserFriendly}</p>
                        <li className='SEOSubtitle'>Safety and Customer Service:</li>
                        <p className='SEODescrpition pt-3'>{MainSEOList.Safety}</p>
                    </ol>



                    <div className="bg-[#051324]">
                        <div className='container lg:px-20'>
                            <div className="text-center">
                                <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
                                    POPULAR OUTSTATION ROUTES
                                </h3>
                            </div>

                            <div className="sm:grid-cols-1 ">
                                <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
                                    <Link to={`${CityRouteData.city}`}>{CityRouteData.city.toUpperCase()} DROP TAXI</Link>
                                </h3>

                                <div className=' grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3 lg:px-20 gap-5'>
                                    {CityRouteData.routes.map((routesDate, index) => (
                                        CityRouteData.city === routesDate ? null : (
                                            <div className="card SEOcard mb-3 border border-red-600 ">
                                                <div className="flex gap-x-2 text-lg items-center text-[#D2C5C5] xl:pl-24 p-4">
                                                    <FaCar className=" text-[20px] text-[#ffc503]" />

                                                    <Link to={`/${CityRouteData.city}/${CityRouteData.city}-to-${routesDate}`}>
                                                        <h5>{CityRouteData.city} -to- {routesDate}</h5></Link>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div >
                    
                    <h2 className='mt-2'>Call taxi service near by</h2>
                    <div className="row">

                        <div className="grid sm:grid-cols-2 grid lg:grid-cols-3 gap-3" >
                            {CityRoutesList.map((city, index) => (
                                <div className="card SEOcard mb-3 p-3 text-center">
                                    <div className="card-body">
                                        <Link to={`/${city.city}`} className="text-decoration-none">
                                            <h5>{city.city} one way drop taxi</h5>
                                        </Link>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


            </div> */}
        </div>
    );
};

export default MainSEO;
