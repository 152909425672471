import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import CityRoutesList from './CityRoutesList'
import { FaCar } from "react-icons/fa";
import Form from "../components/Form";
import { GoogleMap, Marker, LoadScript, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { cityCoordinates } from './LocationComponent';
import SubSEOList from './SubSEOList'



const SubSEO = () => {
    const { City, Routes } = useParams();
    const [directions, setDirections] = useState(null);
    const [startCity, endCity] = Routes ? Routes.split('-to-') : ['', ''];
    const [markers, setMarkers] = useState([]);
    const CityRouteData = CityRoutesList.find(cityData => cityData.city === City);


    // Filter SubSEOList based on the startCity (and possibly endCity, if you want specific content)
    const CityData = SubSEOList.find(cityData => cityData.city === startCity);

    // Load Google Maps API
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCp3Nu6akM8vwQnufoC6r0A3uM4IwE-r7A',
        libraries: ['directions'],
    });

    useEffect(() => {
        window.scrollTo(10, 10);

        // Reset directions and markers whenever route changes
        setDirections(null);
        setMarkers([]);

        if (startCity && endCity && window.google) {
            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer();

            const startCoords = cityCoordinates[startCity];
            const endCoords = cityCoordinates[endCity];

            if (startCoords && endCoords) {
                directionsService.route(
                    {
                        origin: startCoords,
                        destination: endCoords,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            setDirections(result);
                            setMarkers([
                                { position: startCoords },
                                { position: endCoords },
                            ]);
                        } else {
                            console.error('Directions request failed due to ' + status);
                        }
                    }
                );
            }
        }

    }, [startCity, endCity]);

    if (!isLoaded) {
        return <div>Loading Map...</div>;
    }


    function capitalizeWords(string) {
        const validString = string || ''; // Ensure it is always a string
        return validString
            .replace(/-/g, ' ') // Replace all hyphens with spaces
            .split(' ') // Split by space
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(' '); // Join words back with spaces
    }

    return (
        <>
            {/* Form Section */}
            <h1 className='text-center text-[26px] CityHeading'> <span className="pop-outin">{capitalizeWords(Routes)} Drop Taxies</span></h1>


            <div className="form_bg relative grid lg:grid-cols-2 sm-grid-cols-1 justify-center px-3 sm:gap-2 lg:gap-5">
                <div className="bg-white w-full rounded-lg px-4 pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8" id="book_taxi_form">
                    <Form />
                </div>

                {/* Google Map Section */}
                <div className="bg-white w-full rounded-lg px-4 relative  lg:mt-8 mb-4 lg:mb-8">
                    <GoogleMap
                        mapContainerClassName="sm:mt-4 mt-4"
                        mapContainerStyle={{ width: '100%', height: '750px' }}
                        center={cityCoordinates[startCity] || { lat: 13.0827, lng: 80.2707 }}
                        zoom={6}
                    >
                        {markers.map((marker, index) => (
                            <Marker key={index} position={marker.position} label={marker.label} />
                        ))}
                        {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                </div>
            </div>

            {/* Dynamic Content Based on Selected Route */}
            {
                CityData ? (
                    <>
                        <div className='bg-black text-white'></div>
                        <div className="container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
                            <h3 className="font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
                                The Ultimate Road Trip: <span className="pop-outin">{Routes}</span> Excursion with Outstation Taxi
                            </h3>
                            <p className="pt-2">{CityData.UltimateRoad}</p>

                            <h3 className="font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
                                Discovering the Outstation Taxi Advantage:
                            </h3>
                            <p className="pt-2">{CityData.Discovering}</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Doorstep Pickup and Drop-off:</h3>
                            <p className="pt-2">{CityData.Doorstep}</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Comfortable and Well-Maintained Vehicles:</h3>
                            <p className="pt-2">{CityData.Comfortable}</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Professional and Reliable Drivers:</h3>
                            <p className="pt-2">{CityData.Professional}</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Transparent Pricing:</h3>
                            <p className="pt-2">{CityData.Transparent}</p>

                            <div className="my-4 sm:my-6 md:my-10">
                                <div className="pb-6 ps-6 pl-6 pe-6 bg-[#f4c058]">
                                    <h2 className="textCol-secondary">
                                        <span className="pop-outin">{Routes}</span> Taxi Fare Details
                                    </h2>
                                    <table className="w-full table SEOTable table-bordered">
                                        <tbody className="text-left">
                                            <tr>
                                                <th className="p-[5px]">Vehicle Type</th>
                                                <th className="p-[5px]">One Way/Per KM</th>
                                                <th className="p-[5px]">Round Trip/ Per KM</th>
                                                <th className="p-[5px]">Additional Charge</th>
                                            </tr>
                                            {CityData.TaxiFareDetails.map((fare, idx) => (
                                                <tr key={idx}>
                                                    <td className="p-[5px]">{fare.CarType}</td>
                                                    <td className="p-[5px]">{fare.OneWayRate}</td>
                                                    <td className="p-[5px]">{fare.RoundTripRate}</td>
                                                    <td className="p-[5px]">Toll, Hill Station, Permit, Beta, Extra</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="bg-[#051324] p-8">
                                    <div className='container lg:px-20'>
                                        <div className="text-center">
                                            <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
                                                POPULAR OUTSTATION ROUTES
                                            </h3>
                                        </div>

                                        <div className="sm:grid-cols-1 ">
                                            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block CityHeading">
                                                <Link to={`${CityRouteData.city}`} className='CityHeading'>{CityRouteData.city.toUpperCase()} DROP TAXI</Link>
                                            </h3>

                                            <div className=' grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3 lg:px-20 gap-5'>
                                                {CityRouteData.routes.map((routesDate, index) => (
                                                    CityRouteData.city === routesDate ? null : (
                                                        <div className="card SEOcard mb-3 border border-red-600 ">
                                                            <div className="flex gap-x-2 text-lg items-center text-[#D2C5C5] xl:pl-24 p-4">
                                                                <FaCar className=" text-[20px] text-[#ffc503]" />

                                                                <Link to={`/${CityRouteData.city}/${CityRouteData.city}-to-${routesDate}`}>
                                                                    <h5 className='CityHeading'>{CityRouteData.city} To {routesDate}</h5></Link>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">24/7 Customer Support:</h3>
                            <p className="pt-2">{CityData.CustomerSupport}</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">
                                The Enchanting Journey from <span className="pop-outin">{Routes}</span>:
                            </h3>
                            <p className="pt-2">{CityData.JourneyDetails}</p>
                            {CityData.Attractions.map((attraction, idx) => (
                                <p key={idx} className="pt-5">{attraction}</p>
                            ))}
                        </div></>
                ) : (
                    <div className="container px-3 py-4">
                        <div className="container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
                            <h3 className="font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
                                The Ultimate Road Trip: <span className="pop-outin">{Routes}</span> Excursion with Outstation Taxi
                            </h3>
                            <p className="pt-2">Embarking on a journey from the vibrant city of Coimbatore to the charming town of Bangalore is a delightful experience that unveils the cultural richness and natural beauty of Tamil Nadu. As you set foot on this exciting adventure, make the wise choice of using Outstation Taxi, a reliable and efficient transportation service that guarantees a comfortable and hassle-free trip. Whether you are a local resident or a traveler from afar, Outstation Taxi is the perfect companion to ensure a memorable and enjoyable journey across the state.</p>

                            <h3 className="font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
                                Discovering the Outstation Taxi Advantage:
                            </h3>
                            <p className="pt-2">Outstation Taxi is a leading car rental service in Tamil Nadu, offering an array of modern, well-maintained vehicles and experienced drivers. With their commitment to customer satisfaction, they have gained a reputation for providing seamless travel experiences. Here are some key advantages of choosing Outstation Taxi for your Coimbatore to Bangalore trip:</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Doorstep Pickup and Drop-off:</h3>
                            <p className="pt-2">Outstation Taxi understands the value of convenience, and hence, they offer doorstep pickup and drop-off services. Whether you're starting your journey from Coimbatore Central or planning to board the taxi near the airport, Outstation Taxi ensures that your travel begins and ends right at your desired location.",
                            </p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Comfortable and Well-Maintained Vehicles:</h3>
                            <p className="pt-2">"All the vehicles provided by Outstation Taxi are carefully selected and regularly maintained to ensure maximum comfort and safety. You can choose from a range of options, including sedans, SUVs, and even luxury cars, depending on your preferences and requirements.</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Professional and Reliable Drivers:</h3>
                            <p className="pt-2">Outstation Taxi takes pride in its team of professional and courteous drivers who possess excellent knowledge of the routes and destinations. Their commitment to providing a smooth and enjoyable ride is reflected in their punctuality and attentive service, ensuring you have a stress-free journey.</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">Transparent Pricing:</h3>
                            <p className="pt-2">One of the major concerns while opting for a taxi service is hidden costs. Outstation Taxi eliminates this worry by offering transparent pricing. The fare is determined upfront, taking into account the distance, duration, and vehicle type, allowing you to plan your travel budget efficiently.</p>

                            {/* <div className="my-4 sm:my-6 md:my-10">
                                <div className="pb-6 ps-6 pl-6 pe-6 bg-[#f4c058]">
                                    <h2 className="textCol-secondary">
                                        <span className="pop-outin">{Routes}</span> Taxi Fare Details
                                    </h2>
                                    <table className="w-full table SEOTable table-bordered">
                                        <tbody className="text-left">
                                            <tr>
                                                <th className="p-[5px]">Vehicle Type</th>
                                                <th className="p-[5px]">One Way/Per KM</th>
                                                <th className="p-[5px]">Round Trip/ Per KM</th>
                                                <th className="p-[5px]">Additional Charge</th>
                                            </tr>
                                            {CityData.TaxiFareDetails.map((fare, idx) => (
                                                <tr key={idx}>
                                                    <td className="p-[5px]">{fare.CarType}</td>
                                                    <td className="p-[5px]">{fare.OneWayRate}</td>
                                                    <td className="p-[5px]">{fare.RoundTripRate}</td>
                                                    <td className="p-[5px]">Toll, Hill Station, Permit, Beta, Extra</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">24/7 Customer Support:</h3>
                            <p className="pt-2">To cater to any unforeseen circumstances or address any queries or concerns, Outstation Taxi provides round-the-clock customer support. You can reach out to their dedicated team for assistance or guidance at any time during your journey.</p>

                            <h3 className="font-bold text-[16px] md:text-[20px] uppercase mt-5">
                                The Enchanting Journey from <span className="pop-outin">{Routes}</span>:
                            </h3>
                            {/* <p className="pt-2">{CityData.JourneyDetails}</p>
                            {CityData.Attractions.map((attraction, idx) => (
                                <p key={idx} className="pt-5">{attraction}</p>
                            ))} */}

                            "Mahabalipuram: Make a pit stop at the UNESCO World Heritage Site of Mahabalipuram, renowned for its ancient rock-cut temples and stunning coastal views. Admire the intricately carved structures such as the Shore Temple, Pancha Rathas, and Arjuna's Penance.", <br />
                            "Kanchipuram: Known as the 'City of Thousand Temples,' Kanchipuram is famous for its exquisite silk sarees and ancient temples. Take a detour to visit the Kailasanathar Temple, Ekambareswarar Temple, and Varadharaja Perumal Temple, all showcasing remarkable architectural beauty.",
                            <br />
                            "Nilgiri Mountains: As you approach Bangalore, you'll be welcomed by the majestic Nilgiri Mountains. Don't miss the opportunity."
                        </div>
                        <div className="bg-[#051324] p-8">
                            <div className='container lg:px-20'>
                                <div className="text-center">
                                    <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
                                        POPULAR OUTSTATION ROUTES
                                    </h3>
                                </div>

                                <div className="sm:grid-cols-1 ">
                                    <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block CityHeading">
                                        <Link to={`${CityRouteData.city}`} className='CityHeading'>{CityRouteData.city.toUpperCase()} DROP TAXI</Link>
                                    </h3>

                                    <div className=' grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3 lg:px-20 gap-5'>
                                        {CityRouteData.routes.map((routesDate, index) => (
                                            CityRouteData.city === routesDate ? null : (
                                                <div className="card SEOcard mb-3 border border-red-600 ">
                                                    <div className="flex gap-x-2 text-lg items-center text-[#D2C5C5] xl:pl-24 p-4">
                                                        <FaCar className=" text-[20px] text-[#ffc503]" />

                                                        <Link to={`/${CityRouteData.city}/${CityRouteData.city}-to-${routesDate}`}>
                                                            <h5 className='CityHeading'>{CityRouteData.city} To {routesDate}</h5></Link>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                )
            }
        </>
    );
};

export default SubSEO