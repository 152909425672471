import React from "react";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";
import bg from "../assets/images/servbg.png";
import CityRoutesList from "../SEO/CityRoutesList";

const PopularOutstations = () => {
  return (
    <div className="bg-[#000]" style={{ backgroundImage: `url(${bg})` }} id="drop-taxi-cities">
      <div className=" container py-4 md:py-6 lg:py-8 mx-auto px-3 sm:px-4 md:px-8">
        <div className=" text-center">
          <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
            Aj Cabs OUTSTATION CAB SERVICES
          </h3>
        </div>

        <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
          {CityRoutesList.map((cityData, index) => (
            <div key={index} >
              <h3 className="text-[21px] CityHeading md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
                <Link to={cityData.city}>
                  {cityData.city} One Way Drop Taxi
                </Link>
              </h3>
              <ul className=" flex flex-col gap-[5px]">
                {cityData.routes.map((route, index) => (
                  <div key={index}>{cityData.city.toLowerCase() === route.toLowerCase() ? '' :
                    <li className="flex gap-x-2 text-lg items-center  FamousTextList text-white">
                      <FaCar className=" text-[20px] text-[#ffc503]" />

                      <Link to={`/${cityData.city.toLowerCase()}/${cityData.city.toLowerCase()}-to-${route.toLowerCase()}`}>
                        <p className='CityHeading'>   {cityData.city} to {route} one way drop taxi </p>
                      </Link>
                    </li>}
                  </div>
                ))}
              </ul>
            </div>
          
          ))}
        </div>

      </div>
    </div>
  );
};

export default PopularOutstations;
